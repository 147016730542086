import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout/Layout'
import Header from '../../components/layout/Header'

const AvailableProperties = ({ location }) => {
    const iframeOrigin = 'https://www.kontor.com'
    const [iframeSource, setIframeSource] = useState(`${iframeOrigin}/public/available-properties`)
    const [canonPathname, setCanonPathname] = useState();
    const [propertyName, setPropertyName] = useState(null)

    useEffect(() => {
        if (window) {
            const handleMessage = ({ origin, data }) => {
                if (origin === iframeOrigin) {
                    window.history.pushState(
                        {},
                        '',
                        `${location.origin}${data.slice(data.slice(1).indexOf('/') + 1)}`
                    )

                    const [propertyParam] = data.slice(1).split('/').slice(3)
                    const propertyName = propertyParam
                        ?.split('-')
                        .map(([firstLetter, ...restLetters]) => `${firstLetter.toUpperCase()}${restLetters}`)
                        .join(' ')
                    setPropertyName(propertyName || null)
                }
            }

            window.addEventListener('message', handleMessage, false)

            return () => {
                window.removeEventListener('message', handleMessage, false)
            }
        }
    }, [location.origin])

    useEffect(() => {
        setIframeSource(`${iframeOrigin}/public${location.pathname}`)
        setCanonPathname(location.pathname);
    }, [location.pathname])

    return (
        <Layout gutters={false} container={false} activePage="/available-properties" location={location}>
            <Header
                title={`${propertyName ? `Available Properties${propertyName ? ` - ${propertyName}` : ''}` : "Available Properties | London Office Spaces | Kontor"}`}
                description="Explore our premium London office spaces. The Kontor team can accommodate any business needs & find companies the perfect office spaces, in prime locations."
                canonicalURL={canonPathname}
            />
            <iframe
                title="Available Properties"
                src={iframeSource}
                loading='lazy'
                width="100vw"
                height="100vh"
                className="block"
                allow="clipboard-read"
                style={{
                    marginBottom: '15rem',
                    height: '100vh',
                    width: '100vw',
                    border: 'none',
                    paddingTop: '100px'
                }}
            />
        </Layout>
    )
}

export default AvailableProperties
